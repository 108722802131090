import { Box , TextField, Button} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import{useState} from 'react';

export default function ContactForm(){
  const[title,setTitle] = useState('');
  const[email,setEmail] = useState('');
  const [message,setMessage] = useState('');
  const[titleError,setTitleError] = useState(false);
  const [emailError,setEmailError] = useState(false);
  const [msgError, setMsgError] = useState(false);
  
  const handleSubmit = (e) =>{
    e.preventDefault();

    setTitleError(false);
    setEmailError(false);
    setMsgError(false);

    if(title == ''){
      setTitleError(true);
    }
    if(email == ''){
      setEmailError(true);
    }
    if(message == ''){
      setMsgError(true);
    }

    if(title && email && message){
      alert('Message Send! Thank you!');
    }

  }

  return(
    <Box>
      <form
        netlify
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <TextField style={{
         marginTop: 20,
        }} 
        onChange={(e)=> setTitle(e.target.value)}
        required variant='outlined' error={titleError} fullWidth label="Your name"
        />
        <TextField style={{
          marginTop: 20,
        }} 
        onChange={(e)=> setEmail(e.target.value)}
        required error={emailError} variant='outlined' fullWidth label="Email"
        />
        <TextField style={{
          marginTop: 20,
        }} 
        onChange={(e)=> setMessage(e.target.value)}
        required variant='outlined' error={msgError} multiline rows={4} fullWidth label="Message"/>
        <Button
          type="submit"
          variant="contained"
          endIcon={<SendIcon/>}
          style={{
            marginTop: 20,
            width:120,
            height:50
          }}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
}