import {Box, Container, Typography, Divider} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ContactForm from '../components/conctact/ContactForn';
import Footer from '../components/Footer';


const useStyles = makeStyles((theme) => ({
  root:{

  },
  title:{
    fontWeight:'bolder',
    marginTop: theme.spacing(15),
    [theme.breakpoints.down('sm')]:{
      textAlign:'center',  
    },
  },
  footer:{
    paddingBottom: theme.spacing(3),
  },
}));

export default function Contact(){
  const styles = useStyles();

  return(
    <Box>
      <Typography
        align="center"
        variant='h5'
        className={styles.title}
      > CONTACT US</Typography>
      <Container>
        <ContactForm/>
      </Container>
      <Container style={{marginTop:'2em'}}>
        <Divider/>
        <Typography style={{fontWeight:'bolder'}} variant='h5' align='center'>OR</Typography>
        <Typography variant='h6' align='center'>
          EMAIL US AT: <i>silentthievesstudio@gmail.com</i>
        </Typography>
      </Container>
      <Container className={styles.footer} >
        <Footer/>
      </Container>
    </Box>
  );
}