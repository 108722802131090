import {Typography, Button, Box, ImageList, ImageListItem} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const useStyles = makeStyles((theme)=>({
    root:{
    minWidth:"100%",
  },
  mainTxt:{
    marginTop: '2em',
    fontWeight: 'bolder',
  },
  secondaryTxt:{
    marginTop: '1em',
    fontWeight: 'lighter'
  },
  btn:{
    marginTop: '2em',
    paddingBottom: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  mobileSec:{
    marginTop: '1em',
    backgroundColor: '#272727',
    [theme.breakpoints.up('lg')]:{
      display:'none',
      position:'relative',
      top:-150
      
    },
  },
}));


export default function GamesSection(){
   const mobileImages = [
    {
      name: 'bouncy ballz screenshot',
      path:  '/assets/bouncy-ballz.png'
    },{
      name: 'gun slinger screenshot',
      path: '/assets/iphone-gun-slinger.png'
    },
  ];

  const styles = useStyles();

  return(
      <Box className={styles.mobileSec}>
         <Typography className={styles.mainTxt} align="center" variant="h5">
          SEE ALL OUR GAMES!
        </Typography>
        <ImageList cols={2} variant="standard" gap={1} rowHeight={350}  sx={{width:400, height:350,m: 'auto'
        }}>
           {mobileImages.map((item)=> (
             <ImageListItem sx={{
               width:{xs:175,md:200},
               height:{xs:175, md:200}
             }}cols={1} key={item.name}>
                <img
                  src={item.path}
                  alt={item.name}
                  loading="lazy"
                  
                />
             </ImageListItem>
           ))}
        </ImageList>
          <div className={styles.btn}>
            <Link
              to="/Games"
              style={{
                textDecoration:'none'
              }}
            >
              <Button 
                color="primary"
                variant="contained"
                size="large"
                endIcon={<NavigateNextIcon/>}
                onClick={()=> console.log('hello')}
              >
                See All Games
              </Button>
            </Link>
           </div>
       </Box>
  );
}