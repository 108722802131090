import {Box, Container, ImageListItem, Typography} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme)=> ({
  root:{
    marginTop: theme.spacing(10),
    display: 'flex',
    justifyItems:'center',
    alignContent:'center'
  },
  grid:{   
    [theme.breakpoints.up('lg')]:{
      display: 'grid',
      gridTemplateColumns: 'auto auto auto',
      gridGap: theme.spacing(1),
      margin: '0 auto',
    },
    [theme.breakpoints.down('sm')]:{
      display: 'grid',
      gridTemplateColumns:'auto',
      gridGap:theme.spacing(1),
      marginLeft:theme.spacing(1),
    },
  },
  game:{
    width:250,
    height:250,
    borderRadius:'25px',
    cursor: 'pointer',
    display:'flex',
    justifySelf:'center',
    alignSelf:'center',
    '&:hover':{
      opacity: 0.9,
    },
  },
  links:{
    [theme.breakpoints.up('lg')]:{
      margin:0,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      position: 'absolute',
      // right: theme.spacing(0.2),
      bottom: theme.spacing(1),
      opacity: 0,
    },
    [theme.breakpoints.down('sm')]:{
      opacity:1,
      display: 'block',
      position: 'absolute',
      bottom:10
    },
  },
  gameTitle:{
    [theme.breakpoints.up('lg')]:{
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      position: 'absolute',
      top: theme.spacing(1),
      opacity:0,
    },
    [theme.breakpoints.down('sm')]:{
      opacity:1,
      display:'block',
      position: 'absolute',
      top:10,
    }
  },
  btn:{
    marginLeft: theme.spacing(2),
  },
  noOpacity:{
    opacity:0,
  },
}));

export default function GameGrid(){

  const styles = useStyles();

  const width = window.innerWidth;

  const breakpoint = 620;

  const allGames = [
    {
      name: 'Gun Slinger 3D',
      imgPath: '/assets/gun-slinger-log.png',
      googlePlay: 'https://play.google.com/store/apps/details?id=com.SilentThievesStudio.TheWildWestSlinger&hl=en&gl=US',
      appStore:'https://apps.apple.com/ca/app/gun-slinger-3d/id1578747754',
      id: 0,
      docId:'gun'
    },

  ];
  const openLink = (link) => window.open(link);

  const handleGameState = (isHovering, id) =>{
    
    let elements  = document.querySelectorAll(`#${id}`);

    if(width <= breakpoint){
        elements.forEach((item)=>{(
        item.style.opacity = 1
      )});
    }else{

    if(isHovering ){
       elements.forEach((item)=>{(
        item.style.opacity = 1
      )});
    }else{
      elements.forEach((item)=>{(
        item.style.opacity = 0
      )});
    }
  }
   
  }

  return(
    <Box className={styles.root}>
      <Container>
          <div className={styles.grid}>
            {allGames.map((item) =>(
              <ImageListItem 
                key={item.id}
                className={styles.game}  
                onMouseOver ={()=> handleGameState(true, item.docId)} 
                onMouseLeave= {()=> handleGameState(false, item.docId)}
                >
                  <img
                    src={item.imgPath}
                    alt={item.name}
                    loading='lazy'    
                    style={{borderRadius:'25px'}}        
                  />
                <Container id={item.docId} className={styles.gameTitle}>
                  <Typography
                    align='center'
                    variant='h5'
                  >
                    {item.name}
                  </Typography>
                </Container>
                <Box sx={{xs:{opacity:1}}}  id={item.docId} className={styles.links}>
                  <img
                    className={styles.btn}
                    src="assets/google-play-badge.svg"
                    alt="android icon"
                    width={100}
                    height={100}
                    loading="lazy" 
                    onClick={()=> openLink(item.googlePlay)}
                  />
                  <img
                    className={styles.btn}
                    src="assets/download_ios.svg"
                    alt="ios icon"
                    width={100}
                    height={100}
                    loading="lazy"
                    onClick={()=> openLink(item.appStore)}              
                  /> 
                </Box>
              </ImageListItem>
            ))}
          </div>
      </Container>
    </Box>
  );
}