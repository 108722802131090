import {Box ,Container,Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const IMG_WIDTH = 250;
const IMG_HEIGHT = 250;

const useStyles = makeStyles((theme) =>({
  box:{
    border: 'solid 2px #3B74BF',
    height: IMG_HEIGHT,
    width:IMG_WIDTH,
    display:'flex',
    justifyContent:'center',
    alignContent:'center',
    [theme.breakpoints.down('sm')]:{
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width:'60%',
    },
  },
  img:{
  [theme.breakpoints.down('sm')]:{
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width:'100%',
    },
  },
  creator:{
    display:'flex',
    justifyItems:'space-between',
    alignContent:'center',
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]:{
      display: 'inline-block',
      margin:0
    },
  },
  link:{
    color: '#3B74BF',
    [theme.breakpoints.down('sm')]:{
      display:'flex',
      justifyContent:'center'
    },
  },
  text:{
    [theme.breakpoints.down('sm')]:{
      textAlign:'center',
    },
  },
}));

export default function CreatorProfile({fullName, role, imgPath, linkedinLink}){
  const styles = useStyles();
  
  return(
     <Container className={styles.creator}>
       <Box className={styles.box}>
         <img
           src={imgPath}
           alt={fullName}
           loading="lazy"
           width={IMG_WIDTH - 5}
           height={IMG_HEIGHT - 5}
           className={styles.img}
         />
       </Box>
       <Container>
         <Typography className={styles.text} align="left" variant="h5">{fullName}</Typography>
         <Typography className={styles.text} align="left" variant="h6">{role}</Typography>
         <a className={styles.link} href={linkedinLink}>LinkedIn</a>
       </Container>
     </Container>
  );
}