import {Box, Container, Typography, IconButton} from '@mui/material';
import { makeStyles } from "@mui/styles";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const useStyles = makeStyles((theme) => ({
  root:{
    marginTop: theme.spacing(4),
  },
  mainTxt:{
    fontWeight: 'bolder',
  },
  btn:{
    backgroundColor: '#272727',
    border: 'solid thin #3B74BF',
    width:70,
    height:70,
    display: 'flex',
    justifyContent: 'center',
    alignContent:'center',
   '&:hover':{
      cursor: 'pointer',
      opacity: 0.9,
      color: '#3B74BF',
    },
  },
  btns:{
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent:'space-evenly',
    alignContent:'center'
  },
  links:{
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent:'space-evenly',
    alignContent:'center' ,
    gap: theme.spacing(1),
  },
  link:{
    // '&:hover':{
    //   cursor:'pointer',
    //   textDecoration: 'underline',
    //   color: '#3B74BF',
    // },
  },
}));

export default function Footer(){

  const facebookLink = "https://www.facebook.com/SilentThieves";
  const instagramLink = "https://www.instagram.com/silentthievesdev/";
  const twitterLink = "https://twitter.com/silent_thieves";

  const styles = useStyles();

  const handleClick = (url) =>{
    window.open(url);
  };

  return(
    <Box className={styles.root}>
        <Typography className={styles.mainTxt} variant='h6' align="center">FOLLOW US ON SOCIAL MEDIA</Typography>
        <Container className={styles.btns}>
            <Box className={styles.btn}>
              <IconButton
                color='inherit'
                size='large'
                disableRipple
                onClick={()=> handleClick(facebookLink)}
              >
                <FacebookIcon 
                  style={{
                    width:50,
                    height:50,
                  }}
                />
              </IconButton>
            </Box>
             <Box className={styles.btn}>
              <IconButton
                color='inherit'
                size='large'
                disableRipple
                onClick={()=> handleClick(instagramLink)}
              >
                <InstagramIcon 
                  style={{
                    width:50,
                    height:50,
                  }}
                />
              </IconButton>
            </Box>
             <Box className={styles.btn}>
              <IconButton
                color='inherit'
                size='large'
                disableRipple
                onClick={()=> handleClick(twitterLink)}
              >
                <TwitterIcon 
                  style={{
                    width:50,
                    height:50,
                  }}
                />
              </IconButton>
            </Box>
        </Container>
         <Container className={styles.links}>
          {/* <Typography className={styles.link} variant="h8" align='center'>Terms of Use</Typography> */}
          <Typography className={styles.link} variant="h8" align='center'>&copy; SILENT THIEVES 2021. All Rights Reserved</Typography>
          {/* <Typography className={styles.link} variant="h8" align='center'>Privacy Policy</Typography> */}
         </Container>
    </Box>
  );
}