import {Box ,Container,Typography,Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CreatorProfile from "../components/About/CreatorProfile";
import Footer from "../components/Footer";

const useStyles = makeStyles((theme) =>({
  root:{
    display:'grid',
    gridTemplateColumns: 'auto auto',
    [theme.breakpoints.down('sm')]:{
      display:'flex',
      justifyContent: 'center',
      alignContent: 'center',
    },
    paddingBottom:theme.spacing(3),
  
  },
  title:{
    fontWeight:'bolder',
    marginTop: theme.spacing(15),
    [theme.breakpoints.down('sm')]:{
      textAlign:'center',  
    },
  },
  text:{
    paddingTop:theme.spacing(2),
    fontWeight: 'light',
    [theme.breakpoints.down('sm')]:{
      textAlign:'center',  
    },
  },
  image:{
    [theme.breakpoints.down('sm')]:{
      display:'none',
    },
  },
  creators:{
    paddingTop: theme.spacing(2),
    backgroundColor:'#272727',
    paddingBottom: theme.spacing(2),
  },
}));

export default function About(){
  const styles = useStyles();

  return(
    <Box>
      <Box className={styles.root}> 
        <Container>
          <Typography
           variant="h4"
           align="left"
           className={styles.title}
          >
            ABOUT US
          </Typography>
          <Typography
            variant="h6"
            align="left"
            className={styles.text}
          >
            The Silent Thieves Studio was created by Lukas Tomasek a recent graduate student from a George Brown college located in Toronto, Canada.
          </Typography>
          <Typography
            variant="h6"
            align="left"
            className={styles.text}
          >
          Recently a former student Ahmed Mohammed joined a team, our goal is to create new trends and high quality games in the mobile space.
          </Typography>
        </Container>      
        <Container className={styles.image}>
          <img
            src="assets/laptop-fps.png"
            alt="game concept work"
            loading="lazy"
            width={550}
            height={550}
          />
        </Container>
      </Box>
      <Box className={styles.creators}>
        <CreatorProfile
          fullName="Lukas Tomasek"
          role="Founder/Lead Developer"
          imgPath="assets/lukas-tomasek.jpeg"
          linkedinLink="https://www.linkedin.com/in/lukas-tomasek-7826161a5/"
        />
        <Divider/>
        <CreatorProfile
          fullName="Ahmed Mohammed"
          role="UX/UI Designer"
          imgPath="assets/ahmed.jpg"
          linkedinLink="https://www.linkedin.com/in/ahmedmohamed641/"
        />
        {/* <Divider/> */}
      </Box>
      <Footer/>
    </Box>
  );
}