import {Typography,Container, AppBar, Toolbar, Box,IconButton, 
Drawer,List,Divider,ListItem, ListItemText, ListItemIcon} from '@mui/material';
import {useState} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {Link ,useLocation} from 'react-router-dom';
import '../App.css';

export default function Navigation(){
  const [open,setOpen] = useState(false);

  const pages = [
    {
      id: 0,
      name: 'Home',
      path: '',
      exactPath: '/'
    },{
      id:1,
      name: 'Games',
      path: 'Games',
      exactPath: '/Games'
    },{
      id:2,
      name: 'About',
      path: 'About',
      exactPath: '/About'
    },{
      id:3,
      name: 'Contact',
      path: 'Contact',
      exactPath: '/Contact'
    }
  ]
  const drawerWidth = window.innerWidth;
  const location = useLocation();

  return(
    <Box sx={{flexGrow:1}}>
      <AppBar sx={{backgroundColor:'#3B74BF'}} position="fixed">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h5"
              noWrap
              component="div"
              align="center"
              sx={{mr:2, display:{xs:'flex', md:'flex', }}}
            >
             <Link style={{
               color: 'white',
               fontWeight: 'bolder',
               textDecoration: 'none',
               '&:hover':{
                cursor: 'pointer',
                
               },
             }} to="/">Silent Thieves</Link>
            </Typography>
            <Container   sx={{display: {xs: 'none', md: 'flex'}, alignContent: 'center', justifyContent: 'center'}}>
              {pages.map((page)=>(
                <Link  
                  to={`/${page.path}`}
                  style={{marginLeft: 80, fontSize:24,color: 'white',
                   }}
                  className={location.pathname === page.exactPath ? 'activeLink' : 'link'}
                  key={page.id}
                  >
                   {page.name}
                </Link>
              ))}
            </Container>
            <Box disableGutters sx={{flexGrow:1,display:{xs: 'block', md:'none'}, position
          :{xs:'absolute'}, right: {xs: 0}}}>
              <IconButton
                color="inherit"
                size="large"
                edge="end"
                onClick={()=>setOpen(true)}
              >
                <MenuIcon/>
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer   
        sx={{
          display: 'block',
          '& .MuiDrawer-paper':{
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: '#3B74BF'
          },
        }}
        variant="temporary"
        anchor="right"
        open={open}
        ModalProps={{keepMounted: true,}}
      >
        <List>
          <Container>
            <ListItemIcon
              sx={{
                '&:hover':{
                  cursor: 'pointer'
                },
                color: 'white'
              }}
              onClick={()=> setOpen(false)}
            >
              <CloseIcon/>
            </ListItemIcon>
            <Divider color="white"/>
          </Container>
          {pages.map((link)=>(
            <ListItem key={link.id}>
              <ListItemText  style={{
                color: 'white',
                textTransform: 'capitalize',
              }}>
                <Link 
                className={location.pathname === link.exactPath ? 'activeLink' : 'link'}
                style={{
                  color: 'white',
                }}
                to={`/${link.path}`}
                onClick={()=> setOpen(false)}
                >{link.name}</Link>
              </ListItemText>
               <Divider/>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}