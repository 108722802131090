import {Box,} from "@mui/material";
import HomeIntro from "../components/home/HomeIntro";
import GamesSection from "../components/home/GamesSection";
import NewGame from "../components/home/NewGame";
import Footer from "../components/Footer";

export default function Home(){
  return(
    <Box>
        <HomeIntro 
         header="CREATING UNIQUE GAME EXPERIENCES"
         subHeader="We are determined to create high quality mobile game experiences and trends."
        />
        <GamesSection/>
        <NewGame/>
        <Footer/>
    </Box>
  );
}