import { Container, Typography, Button} from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box } from "@mui/system";
import {Link} from 'react-router-dom';


const useStyles = makeStyles((theme)=>({
  mainTxt:{
    marginTop: '2em',
    fontWeight: 'bolder',
    [theme.breakpoints.up('lg')]:{
      textAlign: 'left',
    },
  },
  secondaryTxt:{
    marginTop: '1em',
    fontWeight: 'lighter',
     [theme.breakpoints.up('lg')]:{
      textAlign: 'left',
      fontSize: 18,
    },
  },
  btn:{
    marginTop: '2em',
    paddingBottom:'1em',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]:{
      justifyContent: 'left',
      marginLeft:-25,
    },
  },
  root:{
    marginTop:'5em',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    [theme.breakpoints.down('md')]:{
     display:'block'
    },
  },
  imgs:{
    marginTop: '2em',
    display:'grid',
    gridTemplateColumns: 'auto auto',
    // display:'flex',
    // justifyContent: 'center',
    // alignContent: 'center',
    [theme.breakpoints.down('md')]:{
     display:'none'
    },
  },
  link:{
    color: 'white',
    textDecoration: 'none',
  },
}));

export default function HomeIntro({header, subHeader}){

  const styles = useStyles();

  return(
      <Container  className={styles.root}>
        <Box className={styles.intro}>
          <Typography className={styles.mainTxt} align="center" variant="h5">
            {header}
          </Typography>
          <Typography className={styles.secondaryTxt}  align="center" variant="h6">
            {subHeader}
          </Typography>
          <Container  className={styles.btn}>
            <Link className={styles.link} to="/About">
              <Button 
                color="primary"
                variant="contained"
                size="large"
                endIcon={<NavigateNextIcon/>}
              >
                Learn More
              </Button>
            </Link>
          </Container>
        </Box>
        <Container className={styles.imgs}> 
          <img
            src="/assets/iphone-gun-slinger.png"
            alt="iphone screenshot"
            loading="lazy"
            width={220}
            height={400}
            style={{
              position:'relative',
              left:60,
              top:60,
            }}
          />
           <img
            src="/assets/ipad-gun-slinger.png"
            alt="iphone screenshot"
            loading="lazy"
            width={650}
            height={450}
          />
        </Container>
     </Container>
  );
}