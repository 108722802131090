import {Box,Container, Typography} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../components/Footer';
import GameGrid from '../components/games/GameGrid';

const useStyles = makeStyles((theme)=>({
  root:{
    marginTop: theme.spacing(25),
  },
  mainTxt:{
    marginTop: theme.spacing(15),
    fontWeight: 'bolder',
  },
}));

export default function Games(){

  const styles = useStyles();

  return(
    <Box>
      <Container>
         <Typography className={styles.mainTxt} align='center' variant='h6' component='h1'>
           PLAY OUR GAMES
          </Typography>
      </Container>
      <Container >
         <GameGrid />
      </Container>
      <Container className={styles.root}>
          <Footer/>
      </Container>
    </Box>
  );
}