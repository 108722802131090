import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Navigation from './pages/Navigation';
import Home from "./pages/Home";
import Games from './pages/Games';
import About from './pages/About';
import Contact from './pages/Contact';
import './index.css';

const dark = {
  typography:{
    fontFamily:[
      'Poppins',
      'Bakbak One',
      'Roboto'
    ].join(','),
  },
  palette:{
    mode: 'dark',
    primary:{
      main: '#3B74BF',
    },

  },
}


function App() {
  const customTheme = createTheme(dark);
  return (
    <Router>
      <ThemeProvider theme={customTheme}>
        <CssBaseline/>
          <Navigation/>
          <Routes>
            <Route exact path="/" element={<Home/>}/>
          </Routes>
          <Routes>
            <Route exact path="/Games" element={<Games/>}/>
          </Routes>  
          <Routes>
            <Route exact path="/About" element={<About/>}/>
          </Routes>        
          <Routes>
            <Route exact path="/Contact" element={<Contact/>}/>
          </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
