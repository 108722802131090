import {Typography, Box, Container, Button} from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) =>({
    root:{
    minWidth:"100%",
  },
  mainTxt:{
    marginTop: '1em',
    fontWeight: 'bolder',
  },
  secondaryTxt:{
    fontWeight: 'lighter'
  },
  btn:{
    display: 'flex',
    alignContent: 'space-evenly',
    justifyContent: 'space-evenly',
    transition: 'all ease-in-out',
    '&:hover':{
      cursor: 'pointer',
     
    },
  },
  img:{
    display: 'flex',
    alignContent: 'space-evenly',
    justifyContent: 'space-evenly',
    transition: 'all ease-in-out',
    '&:hover':{
      cursor: 'pointer', 
    },
   [theme.breakpoints.down('sm')]:{
     width: 150,
     margin: 6
   }

  },
  section:{
    marginTop: '4em',
    backgroundColor: '#272727',   
  },
  center:{
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
 
  },
  fullScreen:{
    display:'grid',
    gridTemplateColumns: 'auto 500px auto',
  },
  link:{
    color:'white',
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]:{
      display:'none',
    },
  },
}));




export default function NewGame(){
  const styles = useStyles();

  const handleClick = (url) =>{
    window.open(url);
  };

  return(
    <Box className={styles.section}>
    <Container className={styles.mobile} sx={{display: {lg:'none'}}}>
      <Typography className={styles.mainTxt} align="center" variant="h6">
        CHECK OUT OUR LATEST GAME!
      </Typography>
      <Container className={styles.center} >
        <img
          src="/assets/gun-slinger-log.png"
          alt="gun slinger logo"
          loading="lazy"
          width={150}
          height={150}
        />
      </Container>
        <Typography className={styles.secondaryTxt} align="center" variant="h6">
          Free Download on iOS and Android
        </Typography>
        <Container sx={{display:'inline-block'}} className={styles.center}>
           <img
            className={styles.img}
            src="assets/google-play-badge.svg"
            alt="android icon"
            width={190}
            height={100}
            loading="lazy" 
            onClick={()=> handleClick('https://play.google.com/store/apps/details?id=com.SilentThievesStudio.TheWildWestSlinger&hl=en&gl=US')}
           /> 
           <img
            className={styles.img}
            src="assets/download_ios.svg"
            alt="ios icon"
            width={180}
            height={100}
            loading="lazy"        
            onClick={()=> handleClick('https://apps.apple.com/ca/app/gun-slinger-3d/id1578747754')}
          /> 
        </Container>
      </Container>
      <Box sx={{display: {xs:'none', lg:'block'}}}> 
        <Container className={styles.fullScreen} sx={{display: {lg:'block', xs: 'none'}}}>
            <Container sx={{
              mt: '2em'
            }}>
            <img
              src="/assets/gun-slinger-log.png"
              alt="gun slinger logo"
              loading="lazy"
              width={250}
              height={250}
           />  
          </Container>
         <Container>
            <Typography variant="h6" className={styles.mainTxt} align="left">CHECK OUT OUR LATEST GAME!</Typography>
            <Typography variant="h6" className={styles.secondaryTxt} align="left">Free Download on iOS and Android</Typography>
            <Container sx={{
              position: 'relative',
              left: -23
            }}>
              <img
                className={styles.img}
                src="assets/google-play-badge.svg"
                alt="android icon"
                width={180}
                height={100}
                loading="lazy" 
          
                onClick={()=> handleClick('https://play.google.com/store/apps/details?id=com.SilentThievesStudio.TheWildWestSlinger&hl=en&gl=US')}
               /> 
               <img
                className={styles.img}
                src="assets/download_ios.svg"
                alt="ios icon"
                width={180}
                height={100}
                loading="lazy" 
                onClick={()=> handleClick('https://apps.apple.com/ca/app/gun-slinger-3d/id1578747754')}
                
              /> 
            </Container>
         </Container>    
            <Container sx={{
              display: 'flex'
            }}>
               <img
                src="/assets/iphone-gun-slinger.png"
                alt="iphone screenshot"
                loading="lazy"
                width={220}
                height={400}
            
               />
               <img
                src="/assets/gun-slinger-2.png"
                alt="iphone screenshot"
                loading="lazy"
                width={220}
                height={400}
               />
               
            </Container>

      </Container>
      </Box>
      <Container>
        <Typography sx={{ display:{
          xs:'none',
        }
        }}className={styles.mainTxt} variant="h6" align="center">SEE ALL OUR GAMES!</Typography>
       <Container sx={{mt:'1em', pb: '1em'}}  className={styles.btn}>
         <Link className={styles.link} to="/Games">
          <Button 
            color="primary"
            variant="contained"
            size="large"
            endIcon={<NavigateNextIcon/>}
         
          >
            SEE ALL GAMES
          </Button>
         </Link>
          </Container>
      </Container>
    </Box>
    
  );
};